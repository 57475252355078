import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@theme-ui/components';
import SEO from 'components/atoms/Seo';
import Layout from 'components/organisms/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Fade } from 'react-awesome-reveal';

const Index = () => (
  <Layout>
    <SEO />
    <Container>
      <Flex
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          pb: [5, 5, 5, 5],
          px: 3,
        }}
      >
        <Fade cascade fraction={0.25} triggerOnce>
          <Heading as="h1" sx={{ mb: 5 }}>
            Contact
          </Heading>
        </Fade>
        <Text
          sx={{ mb: 2, textDecoration: 'none', fontSize: [22, 22, 24, 24] }}
        >
          High Scream Limited
        </Text>
        <Fade cascade fraction={0.25} triggerOnce>
          <Link
            variant="links"
            href="mailto:info@high-scream.com?subject=I am getting in touch from your website"
          >
            <Text
              sx={{ mb: 2, textDecoration: 'none', fontSize: [20, 20, 22, 22] }}
            >
              info@high-scream.com
            </Text>
          </Link>
        </Fade>
        <Fade cascade fraction={0.25} triggerOnce>
          <Link variant="links" href="tel:+44 203 744 299">
            <Text sx={{ mb: 2, fontSize: [20, 20, 22, 22] }}>
              +44 (0)203 744 2999
            </Text>
          </Link>
        </Fade>
        <Fade cascade fraction={0.25} triggerOnce>
          <Text sx={{ mb: 4, fontSize: [20, 20, 22, 22] }}>
            Warwick House, Chapone Place, London W1D 3BF
          </Text>
        </Fade>

        <Fade cascade fraction={0.25} triggerOnce>
          <Link
            target="_blank"
            title="Get Directions"
            href="https://www.google.co.uk/maps/dir//High+Scream,+Warwick+House,+Chapone+Pl,+London+W1D+3BF/@51.5142493,-0.1327388,20z/data=!4m16!1m6!3m5!1s0x487604d309f31103:0xa6e0e40599806b02!2sHigh+Scream!8m2!3d51.5142641!4d-0.1325004!4m8!1m0!1m5!1m1!1s0x487604d309f31103:0xa6e0e40599806b02!2m2!1d-0.1325004!2d51.5142641!3e1"
            sx={{ display: 'inline-block', mx: 'auto' }}
          >
            <StaticImage
              src="../images/location.png"
              alt="Our location"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
        </Fade>
      </Flex>
    </Container>
  </Layout>
);

export default Index;
